var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative h-10"},[_c('button',{staticClass:"h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('Icon',{staticClass:"h-5 fill-current",attrs:{"icon":"notifications"}}),(_vm.unreadNotifications > 0)?_c('div',{staticClass:"absolute top-0 w-4 h-4 ml-2 text-xs leading-tight text-center text-white rounded-full bg-red-base"},[_vm._v(" "+_vm._s(_vm.unreadNotifications)+" ")]):_vm._e(),_c('Icon',{staticClass:"h-2 ml-1 fill-current",attrs:{"icon":"arrowDown"}})],1),(_vm.isOpen)?_c('div',{staticClass:"absolute right-0 z-50 w-64 h-64"},[_c('div',{staticClass:"max-h-full overflow-y-auto text-xs bg-white border-4 border-white rounded-sm shadow-md"},[(
                _vm.notifications.recent.length || _vm.notifications.old.length
            )?_c('div',{staticClass:"flex flex-col"},[_c('button',{staticClass:"w-full p-2 text-right border-b-2 text-gray-dark hover:bg-gray-dark hover:text-white active:bg-red-base focus:outline-none",on:{"click":_vm.deleteNotifications}},[_vm._v(" Limpar tudo ")]),_c('div',{staticClass:"flex flex-col"},[(_vm.notifications.recent.length)?[_c('div',{staticClass:"py-1 pl-2 leading-relaxed text-gray-800 border-b-2 border-gray-dark"},[_vm._v(" Recentes ")]),_c('ul',_vm._l((_vm.notifications.recent),function(notification,index){return _c('li',{key:notification.id,class:[
                                    notification.read_at
                                        ? 'bg-gray-light text-gray-dark'
                                        : 'bg-gray-light text-gray-darker',
                                    index > 0 ? 'mt-1' : '',
                                    'w-full h-12 flex justify-between items-center hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none'
                                ]},[(notification.data.params.externalRoute)?[_c('a',{staticClass:"px-2 truncate",attrs:{"href":notification.data.route,"title":notification.data.message,"target":"_blank"}},[(notification.data.subject)?_c('h1',{staticClass:"pt-1 font-bold"},[_vm._v(" "+_vm._s(notification.data.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.data.message)+" ")])]:[(notification.data.route)?_c('router-link',{staticClass:"px-2 truncate",attrs:{"to":{
                                        name: notification.data.route,
                                        params: notification.data.params
                                    },"title":notification.data.message}},[(notification.data.subject)?_c('h1',{staticClass:"pt-1 font-bold"},[_vm._v(" "+_vm._s(notification.data.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.data.message)+" ")]):_c('div',{staticClass:"px-2 truncate",attrs:{"title":notification.data.message}},[(notification.data.subject)?_c('h1',{staticClass:"pt-1 font-bold"},[_vm._v(" "+_vm._s(notification.data.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.data.message)+" ")])],_c('div',{staticClass:"flex h-full"},[_c('button',{staticClass:"flex items-center p-3 border-l-2 hover:bg-red-base hover:text-white active:text-white active:bg-red-800",attrs:{"title":"Excluir"},on:{"click":function($event){return _vm.deleteNotification(
                                            notification.id
                                        )}}},[_c('Icon',{staticClass:"h-4 fill-current",attrs:{"icon":"close"}})],1),(notification.read_at)?_c('div',{staticClass:"flex items-center p-3 border-l-2",attrs:{"title":"Lido"}},[_c('Icon',{staticClass:"h-4 fill-current text-green-base",attrs:{"icon":"verified"}})],1):_c('button',{staticClass:"flex items-center p-3 border-l-2 hover:bg-green-base hover:text-white active:text-white active:bg-green-800",attrs:{"title":"Marcar como lido"},on:{"click":function($event){return _vm.markAsRead(notification.id)}}},[_c('Icon',{staticClass:"h-4 fill-current",attrs:{"icon":"verified"}})],1)])],2)}),0)]:_vm._e(),(_vm.notifications.old.length)?[_c('div',{staticClass:"py-1 pl-2 leading-relaxed text-gray-800 border-b-2 border-gray-dark"},[_vm._v(" Anteriores ")]),_c('ul',_vm._l((_vm.notifications.old),function(notification,index){return _c('li',{key:notification.id,class:[
                                    notification.read_at
                                        ? 'bg-gray-light text-gray-dark'
                                        : 'bg-gray-light text-gray-darker',
                                    index > 0 ? 'mt-1' : '',
                                    'w-full h-12 flex justify-between items-center hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none'
                                ]},[(typeof notification.data.params !== 'undefined')?[_c('a',{staticClass:"px-2 truncate",attrs:{"href":notification.data.route,"title":notification.data.message,"target":"_blank"}},[(notification.data.subject)?_c('h1',{staticClass:"pt-1 font-bold"},[_vm._v(" "+_vm._s(notification.data.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.data.message)+" ")])]:[(notification.data.route)?_c('router-link',{staticClass:"px-2 truncate",attrs:{"to":{
                                        name: notification.data.route,
                                        params: notification.data.params
                                    },"title":notification.data.message}},[(notification.data.subject)?_c('h1',{staticClass:"pt-1 font-bold"},[_vm._v(" "+_vm._s(notification.data.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.data.message)+" ")]):_c('div',{staticClass:"px-2 truncate",attrs:{"title":notification.data.message}},[(notification.data.subject)?_c('h1',{staticClass:"pt-1 font-bold"},[_vm._v(" "+_vm._s(notification.data.subject)+" ")]):_vm._e(),_vm._v(" "+_vm._s(notification.data.message)+" ")])],_c('div',{staticClass:"flex h-full"},[_c('button',{staticClass:"flex items-center p-3 border-l-2 hover:bg-red-base hover:text-white active:text-white active:bg-red-800",attrs:{"title":"Excluir"},on:{"click":function($event){return _vm.deleteNotification(
                                            notification.id
                                        )}}},[_c('Icon',{staticClass:"h-4 fill-current",attrs:{"icon":"close"}})],1),(notification.read_at)?_c('div',{staticClass:"flex items-center p-3 border-l-2",attrs:{"title":"Lido"}},[_c('Icon',{staticClass:"h-4 fill-current text-green-base",attrs:{"icon":"verified"}})],1):_c('button',{staticClass:"flex items-center p-3 border-l-2 hover:bg-green-base hover:text-white active:text-white active:bg-green-800",attrs:{"title":"Marcar como lido"},on:{"click":function($event){return _vm.markAsRead(notification.id)}}},[_c('Icon',{staticClass:"h-4 fill-current",attrs:{"icon":"verified"}})],1)])],2)}),0)]:_vm._e()],2)]):_c('div',{staticClass:"w-full p-2 text-right text-gray-dark"},[_vm._v(" Nenhuma notificação ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }