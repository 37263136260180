<script>
import Modal from "@/core/components/Modal";
import PdfCertificate from "@/domains/lines/views/schedules/components/PdfCertificate.vue";

export default {
    components: {
        Modal,
        PdfCertificate,
    },
    props: {
        plate: { type: String, default: null },
    },
    data: () => {
        return {
            platePDF: null,
        };
    },
    watch: {
        closed: function () {
            this.page = 1;
        },
    },
    mounted() {
        if (this.plate) {
            this.generateIdentificationPlate();
        }
    },
    methods: {
        async generateIdentificationPlate() {
            try {
                const response = await this.$axios.get(
                    "/api/transport/technical-info/" + this.plate +"/plate",
                    {
                        dataType: "binary",
                        processData: false,
                        responseType: "arraybuffer",
                    }
                );
                let blob = new Blob([response["data"]], {
                    type: response.headers["content-type"],
                });
                this.platePDF = {
                    url: window.URL.createObjectURL(blob),
                };
                this.$Progress.finish();
            } catch (e) {
                this.platePDF = null;
                this.$Progress.finish();
            }
        }
    },
};
</script>

<template>
    <div>
        <Modal width="1200" :no-padding="true" :no-margin="true">
            <div slot="body">
                <pdf-certificate
                    :certificate="platePDF"
                    @close="$emit('close')"
                />
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>
