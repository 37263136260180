<script>
export default {
    props: {
        certificate: null
    },
    methods: {
        save() {
            let link = document.createElement("a");
            link.href = this.certificate.url;
            link.download = "certificado_linha.pdf";
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<template>
    <div class="grid gap-4">
        <div class="px-4 py-3 bg-gray-100 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                    type="button"
                    @click.stop="save"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal text-white uppercase transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green sm:leading-tight"
                >
                    Salvar
                </button>
            </span>
            <span
                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="$emit('close')"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal text-gray-700 uppercase transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline sm:leading-tight"
                >
                    <span>Fechar</span>
                </button>
            </span>
        </div>
        <template v-if="certificate">
            <iframe
                :src="certificate.url"
                style="width: 100%; height: 500px; padding: 0; border: none;"
            />
        </template>
    </div>
</template>
