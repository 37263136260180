<script>
export default {
    methods: {
        close() {
            // this.$store.commit("clearLink");
            // this.$router.push({ name: "index" });
            this.$emit("close");
        }
    }
};
</script>

<template>
    <div>
        <div
            class="flex flex-col px-4 pt-5 pb-4 space-y-4 bg-white sm:p-12 sm:pb-4"
        >
            <img
                class="w-48 m-0"
                src="@/core/assets/images/logo_sc_mobi_novo.png"
                alt="SCmobi"
            />
           <h1 class="font-medium">
            Aviso Importante
            </h1>
            <p class="text-sm font-medium">
                Informamos que a equipe de suporte do SCmobi entrará em recesso a partir do dia <strong>19 de dezembro de 2024 </strong> e retornará às atividades no dia <strong>06 de janeiro de 2025</strong>.
            </p>
            <p class="text-sm font-medium">
                Durante este período, não haverá atendimento ao usuário. Agradecemos pela compreensão e desejamos a todos boas festas e um ótimo início de ano!
            </p>
        </div>
        <div
            class="px-4 py-3 bg-gray-100 sm:px-6 sm:flex sm:flex-col sm:place-items-center"
        >
            <span
                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal uppercase transition duration-150 ease-in-out rounded-md shadow-sm btn-blue focus:outline-none sm:leading-tight"
                >
                    Fechar
                </button>
            </span>
        </div>
    </div>
</template>
